<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Multi-domein SSL-certificaat</h1>
        <Price :item="data.staticData.prices.SSLCertificatesPrices[1]"/>
        <h2>Multi-domein SSL certificaat</h2>
        <p>Het multi-domein SSL certificaat (MDC) is de ideale keuze om meerdere domeinnamen of subdomeinen te beveiligen met één certificaat. We spreken van een SAN SSL certificaat of een UCC, afhankelijk van het type dat wordt gebruikt.</p>
        <p>De aanvraag van een multi-domein SSL certificaat is maatwerk, omdat de certificaten beschikbaar zijn op de verschillende beveiligingsniveaus. We kunnen u bij Hosting on Demand goed adviseren over de beschikbare mogelijkheden, aan de hand waarvan we kunnen aangeven wat het best aansluit bij de wensen van uw (online) organisatie.</p>
        <h3>Multi-domein SSL op ieder niveau</h3>
        <p>Het multi-domein SSL certificaat is er in drie verschillende varianten:</p>
        <ul>
          <li>Met bedrijfsgegevens en groene adresbalk (EV, uitgebreide validatie)</li>
          <li>Met bedrijfsgegevens (OV, organisatie validatie)</li>
          <li>Zonder bedrijfsgegevens (DV, domein validatie)</li>
        </ul>
        <p>In alle drie de gevallen zorgt u met een multi-domein SSL certificaat voor een 100% beveiligde verbinding, waardoor u zonder problemen vertrouwelijke gegevens kunt (laten) verzenden. Indien u de groene adresbalk of de bedrijfsgegevens van belang vindt om een betrouwbare indruk op uw bezoekers te maken kunt u gebruik maken van de multi-domein SSL certificaten met OV of EV, afhankelijk van waar uw voorkeur naar uitgaat.</p>
        <h3>SAN SSL en Unified Communications</h3>
        <p>Het multi-domein SSL certificaat kunnen we indelen in de SAN SSL certificaten en de UCC. SAN staat voor Subject Alternative Name en biedt de mogelijkheid om een enkel domein certificaat aan één domein toe te wijzen in het Commun Name veld. Via het CN-veld is het daarnaast mogelijk om meerdere SAN-velden toe te wijzen en op die manier extra domeinen toe te voegen.</p>
        <p>UCC staat voor Unified Communicaties Certificate en is vooral interessant voor het beveiligen van Microsoft Exchange. Sinds Exchange 2007 dient u gebruik te maken van SSL certificering voor autodiscover en het webmail (sub)domein. Een multi-domein SSL certificaat op basis van UCC is dan de ideale keuze.</p>
        <h3>Voordelen van een SSL certificaat bij Hosting on Demand</h3>
        <p>Bij Hosting on Demand profiteert u met een multi-domein SSL certificaat van:</p>
        <ul>
          <li>Een 100% beveiligde verbinding</li>
          <li>Snelle levering van ieder SSL certificaat</li>
          <li>Betere vindbaarheid in Google</li>
        </ul>
        <p>Het multi-domein SSL certificaat is standaard 1 jaar geldig en kan uiteraard jaarlijks verlengd worden. Op die manier zorgen we ervoor dat u daar gebruik van kunt maken in de periode dat u het SSL certificaat nodig heeft voor 100% beveiliging van de verbinding met uw website of applicatie.</p>
        <p>&nbsp;</p>
        <RandomQuote/>
      </div>
      <SSLCertificatesRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Price from '@/components/Price.vue';
import SSLCertificatesRightSideBlocks from '@/components/SSLCertificatesRightSideBlocks.vue';
import RandomQuote from '@/components/RandomQuote.vue';

export default {
  name: 'MultidomainSSLCertificate',
  components: {
    Breadcrumbs,
    Price,
    SSLCertificatesRightSideBlocks,
    RandomQuote,
  },
  setup() {
    const data = inject('data');

    return {
      // store
      data,
    };
  },
}
</script>